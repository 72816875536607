<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span
      class="pointer"
      [routerLink]="['/']"
      routerLinkActive="router-link-active"
      >Palatable Curation</span
    >
    <span class="example-spacer"></span>
    <span class="mr-3">( {{ userRole }} )</span>
    <button mat-raised-button (click)="onLogout()">
      <i class="material-icons">power_settings_new</i> Logout
    </button>
  </mat-toolbar-row>
</mat-toolbar>
