import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'convertArrayLengthToNumber'
} )
export class ConvertArrayLengthToNumberPipe implements PipeTransform {

  transform ( values ): unknown {
    const res = [];
    for ( let i = 1; i < values + 1; i++ ) {
      res.push( i );
    }
    return res;
  }

}
