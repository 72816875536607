import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'filterCheckedMasterData',
  pure: false
} )
export class FilterCheckedMasterDataPipe implements PipeTransform {

  transform ( allValues: any[], args?: any ): any {
    const selected = [];
    const nonSelected = [];
    let all: any;
    if ( allValues.length > 0 ) {
      for ( const val of allValues ) {
        if ( val['checked'] ) {
          selected.push( val );
        } else {
          nonSelected.push( val );
        }
      }
      all = selected.concat( nonSelected );
      return all;
    }
  }

}
