// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebase: {
  // apiKey: 'AIzaSyDIiQIa5ZTAfnrADbxkFrEwIFzbqkwRuOQ',
  // authDomain: 'foodkonnect-65adf.firebaseapp.com',
  // databaseURL: 'https://foodkonnect-65adf.firebaseio.com',
  // projectId: 'foodkonnect-65adf',
  // storageBucket: 'foodkonnect-65adf.appspot.com',
  // messagingSenderId: '282978665028'
  // }
  firebase: {
    apiKey: 'AIzaSyCW82YzTiCIHYgXMvb7Tx1Oa2o1GXWM7dE',
    authDomain: 'palatable-live.firebaseapp.com',
    databaseURL: 'https://palatable-live.firebaseio.com',
    projectId: 'palatable-live',
    storageBucket: 'palatable-live.appspot.com',
    messagingSenderId: '173549968625',
  },

  defaultImage: 'https://foodkonnect.s3.amazonaws.com/default.png',

  // S3 Configuration
  accessKeyId: 'AKIAWYP3PHJMB6FCB4ET',
  secretAccessKey: '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',
  region: 'us-east-1',
  bucket: 'foodkonnect',

  API_URL: 'https://ai.palatable.app:5052/',
  PLACES_API_URL:
    'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAv3BLk4qEdnDgHPu8VL685ns7pxsmsrt4&libraries=places&address=',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
