import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  userRole = 'Curation';
  constructor(private authService: AuthService) {}

  ngOnInit() {
    const userRole = localStorage.getItem('userRole');
    if (userRole === 'Nutrition') {
      this.userRole = 'Nutritionist';
    } else {
      this.userRole = 'Curation';
    }
  }

  onLogout() {
    this.authService.logout();
  }
}
