import { RemoveSpecialCharactersPipe } from './remove-special-characters.pipe';
import { NgModule } from '@angular/core';
import { FilterMasterDataByNamePipe } from './filter-master-data-by-name.pipe';
import { FilterCheckedMasterDataPipe } from './filter-checked-master-data.pipe';
import { ConvertArrayLengthToNumberPipe } from './convert-array-length-to-number.pipe';
import { FilterTemplatesPipe } from './filter-templates.pipe';


@NgModule( {
    declarations: [RemoveSpecialCharactersPipe, FilterMasterDataByNamePipe, FilterCheckedMasterDataPipe, ConvertArrayLengthToNumberPipe, FilterTemplatesPipe],
    exports: [RemoveSpecialCharactersPipe, FilterMasterDataByNamePipe, FilterCheckedMasterDataPipe, ConvertArrayLengthToNumberPipe, FilterTemplatesPipe]
} )
export class PipesModule { }
