import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'filterTemplates'
} )
export class FilterTemplatesPipe implements PipeTransform {

  transform ( value: any, args?: any ): any {
    if ( !args ) { return value; }
    return value.filter( ( ele: any ) => {
      return ele.template_name.toLowerCase().includes( args.toLowerCase() );
    } );
  }

}
