import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'filterMasterDataByName',
  pure: false
} )
export class FilterMasterDataByNamePipe implements PipeTransform {

  transform ( value: any[], args?: any ): any {

    const checked = [];
    const unChecked = [];
    const array = [];

    if ( args ) {

      value.map( ( ele: any ) => {
        if ( ele.checked === true ) {
          checked.push( ele );
        } else {
          unChecked.push( ele );
        }
      } );

      unChecked.map( ( ele: any ) => {
        if ( ele.id.toString().split( '_' ).join( ' ' ).includes( args.toLowerCase() ) ) {
          array.push( ele );
        }
      } );
      return checked.concat( array );

    } else {
      return value;
    }

  }

}
