import { AuthGuard } from './auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/sign-up/sign-up.module').then((m) => m.SignUpModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-restaurant',
    loadChildren: () =>
      import('./pages/add-restaurant/add-restaurant.module').then(
        (m) => m.AddRestaurantModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-restaurant/:restaurantId',
    loadChildren: () =>
      import('./pages/add-restaurant/add-restaurant.module').then(
        (m) => m.AddRestaurantModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'restaurant-profile/:restaurantId',
    loadChildren: () =>
      import('./pages/restaurant-profile/restaurant-profile.module').then(
        (m) => m.RestaurantProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'menu-list/:restaurantId/:category',
    loadChildren: () =>
      import('./pages/menu-list/menu-list.module').then(
        (m) => m.MenuListModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-menu/:restaurantId',
    loadChildren: () =>
      import('./pages/add-menu/add-menu.module').then((m) => m.AddMenuModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-menu/:restaurantId/:menuId',
    loadChildren: () =>
      import('./pages/add-menu/add-menu.module').then((m) => m.AddMenuModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'customization/:restaurantId',
    loadChildren: () =>
      import('./pages/customization/customization.module').then(
        (m) => m.CustomizationModule
      ),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
