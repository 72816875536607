import { SharedService } from './shared.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: firebase.User;
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    public sharedService: SharedService
  ) {}

  async login(email, password) {
    try {
      return await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      this.sharedService.showSnackBar(e.message);
    }
  }

  async signUp(email, password) {
    try {
      return await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
    } catch (e) {
      this.sharedService.showSnackBar(e.message);
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
    firebase.auth().signOut();
  }

  get isLoggedIn(): boolean {
    const user = localStorage.getItem('userId');
    return user !== null;
  }
}
