import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as S3 from 'aws-sdk/clients/s3';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private snackBar: MatSnackBar) {}

  showSnackBar(message) {
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

  filterCategory(array) {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].subCat) {
        array[i]['subCat'] = [];
      }
      if (array[i].subCat) {
        array[i].subCat = Object.keys(array[i].subCat).map(
          (j) => array[i].subCat[j]
        );
        if (Array.isArray(array[i].subCat)) {
          this.filterCategory(array[i].subCat);
        }
      }
    }
    return array;
  }

  getS3Bucket(): any {
    const bucket = new S3({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.region,
    });

    return bucket;
  }

  makePath(path, arr) {
    for (let i = 0; i < arr.length; i++) {
      path = '';
      path += arr[i].key + '/';
      arr[i].path = path;
      if (arr[i].subCat.length) {
        this.makeSubPath(path, arr[i].subCat);
      }
    }
    return arr;
  }

  makeSubPath(path, arr) {
    let subPath = path;
    for (let i = 0; i < arr.length; i++) {
      subPath = path;
      arr[i].path = subPath + arr[i].key + '/';
      if (arr[i].subCat.length) {
        subPath = subPath + arr[i].key + '/';
        this.makeSubPath(subPath, arr[i].subCat);
      }
    }
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  checkedCategory(categories, categoryPath) {
    for (let category in categories) {
      if (categories[category].path.slice(0, -1) === categoryPath) {
        categories[category].template_created = false;
      } else if (Array.isArray(categories[category].subCat)) {
        this.checkedCategory(categories[category].subCat, categoryPath);
      }
    }
    return categories;
  }

  async getBase64(file): Promise<any> {
    return await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
        reject(error);
      };
    });
  }
}
